import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import axiosInstance from "../../axiosInstance";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import { confirmAlert } from "react-confirm-alert";
import recordContext from "../../context/recordContext";

import Loader from "../common/Loader";
import DropdownFilter from "../common/DropdownFilter";
import solvedQuestionRight from "../../assets/images/solvedQuestionRight.svg";
import clockIcon from "../../assets/images/clockIconVieAllQuestionPage.svg";
import rightPaginationArrow from "../../assets/images/rightPaginationArrow.svg";
import leftPaginationArrow from "../../assets/images/leftPaginationArrow.svg";
import { QuestionsType } from '../types/QuestionsSection';
import { toast } from "react-toastify";

const quizzesPerPage = 10;

const convertToIST = (dateString) => {
  const date = new Date(dateString);

  // Calculate IST by adding 5 hours 30 minutes to the UTC time
  const istOffset = 5.5 * 60; // 5 hours 30 minutes in minutes
  const istDate = new Date(date.getTime() + istOffset * 60000); // Adding IST offset in milliseconds

  return istDate;
};

const QuesListingPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { questions, attemptedQuestions, testTime, startTime } = useAppSelector((state) => {
    return state.questions;
  });
  const { user } = useAppSelector((state) => state.auth);
  const location = useLocation();
  const context = useContext(recordContext);

  const candidateTestId = searchParams.get("candidateTestId");

  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [questionStatus, setQuestionStatus] = useState("All");
  const [buttonRange, setButtonRange] = useState({ start: 1, end: 10 });
  const [error, setError] = useState(null);
  const [questionType, setQuestionType] = useState("ALL");
  const [timeLeft, setTimeLeft] = useState("");

  const {
    stopRecording,
  } = context;

  const beforeUnload = useCallback((e) => {
    e.preventDefault();
    endCall();
    return "";
  }, [])

  useEffect(() => {
    window.addEventListener('beforeunload', beforeUnload);

    return () => {
      window.removeEventListener('beforeunload', beforeUnload);
    };
  }, []);

  const calculateTimeLeft = () => {
    const currentTime = new Date();
    const startAtIST = convertToIST(startTime);
    const endsAtIST = convertToIST(testTime);
    const remainingTime = endsAtIST.getTime() - currentTime.getTime();
    const elapsedTime = currentTime.getTime() - startAtIST.getTime();
    console.log(startAtIST, endsAtIST, 'asdfdsa')
    if (remainingTime <= 0) {
      return "00:00:00";
    } else if (elapsedTime >= 0) {
      const hours = Math.floor(remainingTime / (1000 * 60 * 60));
      const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
      console.log(minutes, "minutes")
      if (minutes === 5 && seconds === 0) {
        toast.info("5 minutes left")
      }
      if (minutes === 3 && seconds === 0) {
        toast.info("3 minutes left")
      }
      if (minutes === 1 && seconds === 0) {
        toast.info("Last 1 minute remaing")
      }

      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    } else {
      return "Test not started";
    }
  };

  const endCall = async () => {
    const jobApplicationId = localStorage.getItem("jobApplicationId");
    const token = localStorage.getItem("auth");
    try {
      await axiosInstance.post(
        `/api/v3/job-applications/${jobApplicationId}/evaluate?token=${token}`
      );
      stopRecording();
      navigate(`/`);
    } catch (error) {
      toast.error("Failed to end test. Please try again.");
    }
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      const time = calculateTimeLeft();
      setTimeLeft(time);
      if (time === "00:00:00") {
        endCall();
        clearInterval(intervalId);
      }
    }, 1000);

    // Cleanup on unmount
    return () => clearInterval(intervalId);
  }, []);


  const questionStatusValues = ["All", "Solved", "Unsolved"];
  const questionTypeValues = ["ALL", ...Object.values(QuestionsType)];

  const filteredQuestionsList = useMemo(() => {
    if (!questions.length) return [];

    let filtered = [...questions];

    if (questionType !== "ALL") {
      filtered = filtered.filter(
        (elem) => elem.questionType === questionType
      );
    }

    if (questionStatus === "Solved") {
      filtered = filtered.filter((elem) =>
        attemptedQuestions?.hasOwnProperty(elem.id)
      );
    } else if (questionStatus === "Unsolved") {
      filtered = filtered.filter(
        (elem) => !attemptedQuestions?.hasOwnProperty(elem.id)
      );
    }

    return filtered;
  }, [questions, questionType, questionStatus, attemptedQuestions]);

  const currentPageQuestions = useMemo(() => {
    const startIndex = (currentPage - 1) * quizzesPerPage;
    return filteredQuestionsList.slice(startIndex, startIndex + quizzesPerPage);
  }, [filteredQuestionsList, currentPage]);

  const endTest = () => {
    const jobApplicationId = localStorage.getItem("jobApplicationId")
    const authData = localStorage.getItem("auth");
    const token = authData; // This will remove quotes

    confirmAlert({
      title: "Submit Test",
      message: "Are you sure to end test now? This cant be undone.",
      buttons: [
        {
          label: "End Test",
          onClick: async () => {
            try {
              await axiosInstance.post(
                `/api/v3/job-applications/${jobApplicationId}/evaluate?token=${token}`
              );
              stopRecording();
              navigate(`/`);
            } catch (error) {
              toast.error("Failed to end test. Please try again.");
            }
          },
        },
        { label: "Cancel" },
      ],
    });
  };

  const filterQuestionUpdate = (value) => {
    setQuestionType(value.toUpperCase());
    setCurrentPage(1);
    setButtonRange({ start: 1, end: 10 });
  };

  const filterQuestionStatus = (value) => {
    setQuestionStatus(value);
    setCurrentPage(1);
    setButtonRange({ start: 1, end: 10 });
  };

  const navToQuestion = (queId) => {
    navigate(`/temp-code?candidateTestId=${candidateTestId}`, {
      state: { queId: queId.toString() },
    });
  };

  const totalPages = useMemo(
    () => Math.ceil(filteredQuestionsList.length / quizzesPerPage),
    [filteredQuestionsList]
  );

  const handleNextRange = () => {
    if (currentPage < totalPages) {
      setCurrentPage(prev => prev + 1);

      if (currentPage >= buttonRange.end) {
        setButtonRange(prev => ({
          start: prev.start + 1,
          end: Math.min(prev.end + 1, totalPages)
        }));
      }
    }
  };

  const handlePrevRange = () => {
    if (currentPage > 1) {
      setCurrentPage(prev => prev - 1);

      if (currentPage <= buttonRange.start) {
        setButtonRange(prev => ({
          start: Math.max(prev.start - 1, 1),
          end: prev.end - 1
        }));
      }
    }
  };

  const renderPaginationButtons = () => {
    const buttons = [];
    for (let i = buttonRange.start; i <= buttonRange.end; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => setCurrentPage(i)}
          className={i === currentPage ? "active" : ""}
          disabled={i > Math.ceil(filteredQuestionsList.length / 10)}
        >
          {i}
        </button>
      );
    }
    return buttons;
  };


  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="viewAllQuestionPage">
          <div className="viewAllQestionPageHeader">
            <div className="timerClock">
              <img src={clockIcon} className="clockIcon" alt="Clock Icon" />
              {/* <span className="clockText">{formatTime(remainingTime)}</span> */}
              <span className="clockText">{timeLeft}</span>
            </div>
          </div>
          <div className="test-listing-page-main p-5 flex-grow-1 d-flex flex-column">
            <div className="d-flex px-3">
              <div className="w-75">Questions</div>
              <div className="w-25 d-flex flex-row justify-content-between">
                <div className="questionTypeSelect">
                  <DropdownFilter
                    name="questionType"
                    styleClass="viewAllQnPageDropdown"
                    dropdownName="Type"
                    dropdownOptions={questionTypeValues}
                    dropdownHandleClick={filterQuestionUpdate}
                  />
                </div>
                <div className="questionStatusSelect">
                  <DropdownFilter
                    name="questionStatus"
                    styleClass="viewAllQnPageDropdown"
                    dropdownName="Status"
                    dropdownOptions={questionStatusValues}
                    dropdownHandleClick={filterQuestionStatus}
                  />
                </div>
              </div>
            </div>
            <div className="mt-2 test-segregation-box flex-grow-1">
              {currentPageQuestions.length > 0 ? (
                currentPageQuestions.map((que, i) => (
                  <div className="code-test-card d-flex" key={i}>
                    <div className="w-75">
                      {(currentPage - 1) * 10 + i + 1}. {que.title}
                    </div>
                    <div className="d-flex justify-content-between align-items-center w-25">
                      <div className="test-page-qn-type">
                        {(que.questionType !== QuestionsType.CODING && que.questionType !== QuestionsType.SYSTEM_DESIGN) ? "MCQ" : "Coding"}
                      </div>
                      <button
                        type="button"
                        onClick={() => navToQuestion(que.id)}
                        className="test-page-action-btn col-1 d-flex justify-content-center"
                        style={{
                          backgroundColor: attemptedQuestions[que.id]
                            ? "#404342"
                            : "#B4FF45",
                          color: attemptedQuestions[que.id]
                            ? "#868C8A"
                            : "#131514",
                        }}
                        disabled={attemptedQuestions[que.id]}
                      >
                        {attemptedQuestions[que.id] && (
                          <img
                            src={solvedQuestionRight}
                            alt="Solved Icon"
                            className="solvedTickIcon"
                          />
                        )}

                        {attemptedQuestions[que.id] ? "Solved" : "Solve"}

                      </button>
                    </div>
                  </div>
                ))
              ) : questions.length > 0 ? (
                <h1 className="text-center text-warning">
                  No questions match the selected filters
                </h1>
              ) : (
                <h1 className="text-center">No questions available</h1>
              )}
            </div>
            <div className="viewAllQuestionPagePagination">
              <div className="paginationNoBtns">
                {renderPaginationButtons()}
              </div>
              <div className="paginationNextPrevBtn">
                <button
                  className="leftPaginationArrow"
                  onClick={handlePrevRange}
                  disabled={currentPage === 1}
                >
                  <img
                    className="rightPaginationArrow"
                    src={leftPaginationArrow}
                    alt="Next Question"
                  />
                </button>
                <button
                  className="rightPaginationArrow"
                  onClick={handleNextRange}
                  disabled={currentPage === totalPages}
                >
                  <img
                    className="rightPaginationArrow"
                    src={rightPaginationArrow}
                    alt="Next Question"
                  />
                </button>
              </div>
            </div>
          </div>
          <div className="footerBar">
            <button onClick={endTest}>Submit Test</button>
          </div>
        </div>
      )}
    </>
  );
};

export default QuesListingPage;
