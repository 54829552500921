export enum DifficultyLevel {
    EASY = 'EASY',
    MEDIUM = 'MEDIUM',
    HARD = 'HARD'
}

export enum QuestionsType {
    MCQ_JAVA = "MCQ_JAVA",
    MCQ_PYTHON = "MCQ_PYTHON",
    MCQ_JAVASCRIPT = "MCQ_JAVASCRIPT",
    MCQ_CSHARP = "MCQ_CSHARP",
    MCQ_CPP = "MCQ_CPP",
    MCQ_GO = "MCQ_GO",
    MCQ_RUST = "MCQ_RUST",
    MCQ = "MCQ",
    CODING = "CODING",
    SYSTEM_DESIGN = "SYSTEM_DESIGN",
}

export const COUNT_LIMIT = 50;
export const ITEMS_PER_PAGE_LIMIT = 10;