import {
  fetchQuestionsFailure,
  fetchQuestionsStart,
  fetchQuestionsSuccess,
  setRecruiterData,
} from "../slices/questions/recruiterQuestionSlice";
import axiosInstance from "../../axiosInstance";

export const fetchQuestions =
  (currentPage, questionsPerPage, recruiterId, questionType) =>
  async (dispatch) => {
    try {
      const token = JSON.parse(localStorage.getItem("auth"));
      dispatch(fetchQuestionsStart());

      const response = await axiosInstance.get(
        `https://api.assessmentpad.com/api/v4/questions`,
        {
          params: {
            token,
            // Uncomment below to support server side pagination
            // page: currentPage - 1,
            count: questionsPerPage,
            recruiterId: recruiterId,
          },
        }
      );
      dispatch(
        fetchQuestionsSuccess({
          questionData: response.data.data.data,
          totalPages: response.data.data.totalPages,
        })
      );
    } catch (error) {
      dispatch(fetchQuestionsFailure(error.message));
    }
  };

export const fetchRecruiterData = (email) => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("auth"));
  try {
    const response = await fetch(
      `https://api.assessmentpad.com/api/v3/recruiters/search?token=${token}&email=${encodeURIComponent(
        email
      )}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    if (data?.success) {
      dispatch(setRecruiterData({ id: data.data.id, email: email }));
    }
    return data;
  } catch (error) {
    console.error("Error fetching recruiter data:", error);
    throw error;
  }
};
