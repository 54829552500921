import { confirmAlert } from "react-confirm-alert";
import axiosInstance from "../../axiosInstance";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState, useContext } from "react";
import { toast } from "react-toastify";
import recordContext from "../../context/recordContext";
import rightPaginationArrow from "../../assets/images/rightPaginationArrow.svg";
import leftPaginationArrow from "../../assets/images/leftPaginationArrow.svg";
import rightImage from "../../assets/images/CompletedQuestionRight.svg";
import viewAllQuestions from "../../assets/images/viewAllQuestions.svg";
import clockIcon from "../../assets/images/clockIcon.svg";
import testStopIcon from "../../assets/images/testStopBtn.svg";
import { useAppSelector } from "../../redux/hooks";
import RatingModal from "../RatingModal/RatingModal";

const convertToIST = (dateString) => {
  const date = new Date(dateString);

  // Calculate IST by adding 5 hours 30 minutes to the UTC time
  const istOffset = 5.5 * 60; // 5 hours 30 minutes in minutes
  const istDate = new Date(date.getTime() + istOffset * 60000); // Adding IST offset in milliseconds

  return istDate;
};

const EditorPagination = ({ onQueChange, activeQue }) => {
  const navigate = useNavigate();
  const { theme, user } = useAppSelector((state) => state.auth);
  const [searchParams] = useSearchParams();
  const recorderContext = useContext(recordContext);
  const {
    stopRecording,
  } = recorderContext;

  const { questions, questionType, attemptedQuestions, testTime, startTime } = useSelector(
    (state) => state.questions
  );

  const startAtIST = convertToIST(startTime);
  const endsAtIST = convertToIST(testTime);
  const allQnIDs = questions.map((q) => q.id);
  const totalQuestions = questions.length;
  let currentIndex = questions.findIndex((elem) => elem.id === activeQue);
  const [rightEnd, setRightEnd] = useState(false);
  const [leftEnd, setLeftEnd] = useState(currentIndex === 0);
  const [showRatingModal, setShowRatingModal] = useState(false);
  const [timeLeft, setTimeLeft] = useState("");
  const calculateTimeLeft = () => {
    const currentTime = new Date();
    const remainingTime = endsAtIST.getTime() - currentTime.getTime();
    const elapsedTime = currentTime.getTime() - startAtIST.getTime();
    console.log(remainingTime, elapsedTime, 'remaing')
    if (remainingTime <= 0) {
      return "00:00:00";
    } else if (elapsedTime >= 0) {
      const hours = Math.floor(remainingTime / (1000 * 60 * 60));
      const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
      if (minutes === 5 && seconds === 0) {
        toast.info("5 minutes left")
      }
      if (minutes === 3 && seconds === 0) {
        toast.info("3 minutes left")
      }
      if (minutes === 1 && seconds === 0) {
        toast.info("Last 1 minute remaing")
      }

      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    } else {
      return "Test not started";
    }
  };

  const endCall = async () => {
    const jobApplicationId = localStorage.getItem("jobApplicationId");
    const token = localStorage.getItem("auth");
    try {
      await axiosInstance.post(
        `/api/v3/job-applications/${jobApplicationId}/evaluate?token=${token}`
      );
      stopRecording(false);
      setShowRatingModal(true);
    } catch (error) {
      toast.error("Failed to end test. Please try again.");
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const time = calculateTimeLeft();
      setTimeLeft(time);
      if (time === "00:00:00") {
        endCall();
        clearInterval(intervalId);
      }
    }, 1000);

    // Cleanup on unmount
    return () => clearInterval(intervalId);
  }, []);



  const handlePrevious = () => {
    let previousQnIndex = currentIndex;
    if (currentIndex > 0) {
      previousQnIndex = currentIndex - 1;
      while (
        attemptedQuestions[questions[previousQnIndex].id] &&
        previousQnIndex > 0
      ) {
        previousQnIndex--;
      }
      if (previousQnIndex >= 0) {
        currentIndex = previousQnIndex;
      }
    }
    if (attemptedQuestions[questions[currentIndex].id]) {
      onQueChange(questions[currentIndex].id);
    } else {
      skipQnCnfirmation(currentIndex);
    }
    setLeftEnd(currentIndex === 0);
    setRightEnd(false);
  };

  const handleNext = () => {
    console.log({ currentIndex, totalQuestions, allQnIDs, attemptedQuestions });
    let nextIndex = currentIndex;
    if (currentIndex < totalQuestions - 1) {
      nextIndex = currentIndex + 1;
      while (
        attemptedQuestions[allQnIDs[nextIndex]]?.selectedOptions &&
        nextIndex < totalQuestions - 1
      ) {
        nextIndex++;
      }
    }

    const currentQuestionId = allQnIDs[currentIndex];
    const nextQuestionId = allQnIDs[nextIndex];
    console.log({
      currentQuestionId,
      hasAttempted: attemptedQuestions[currentQuestionId],
      hasSelectedOptions:
        attemptedQuestions[currentQuestionId]?.selectedOptions,
      attemptedQuestions,
    });
    console.log({
      attemptedQuestions:
        attemptedQuestions[currentQuestionId]?.selectedOptions,
    });

    if (attemptedQuestions[currentQuestionId]?.selectedOptions.length > 0) {
      console.log("here");
      onQueChange(nextQuestionId);
    } else {
      skipQnCnfirmation(nextIndex);
    }
    console.log({ nextIndex, totalQuestions });
    setRightEnd(nextIndex === totalQuestions - 1);
    setLeftEnd(false);
  };

  const getCurrentIndex = () => {
    currentIndex = questions.findIndex((q) => q.id === activeQue);

    let start = Math.max(0, Math.min(currentIndex, totalQuestions - 10));
    let end = Math.min(start + 10, totalQuestions);

    return questions.slice(start, end);
  };

  const skipQnCnfirmation = (index) => {
    console.log({ index, attemptedQuestions, allQnIDs });
    const attemptedQnids = Object.keys(attemptedQuestions);
    if (!attemptedQnids.includes(allQnIDs[index])) {
      confirmAlert({
        title: "Are you sure you want to skip this question ?",
        message:
          "You cannot come back to this question later. Even if you don't know the answer, just pick the answer you feel best about. You might get it right!",
        buttons: [
          {
            label: "Skip question",
            className: "btnSkipOutline",
            onClick: async () => {
              onQueChange(questions[index].id);
            },
          },
          { label: "Choose answer", className: "btnSkipPrimary" },
        ],
      });
    }
  };

  const endTest = () => {
    const jobApplicationId = localStorage.getItem("jobApplicationId");
    const token = localStorage.getItem("auth");
    confirmAlert({
      title: "Submit Test",
      message: "Are you sure to end test now? This cant be undone...",
      buttons: [
        {
          label: "End Test",
          onClick: async () => {
            try {
              await axiosInstance.post(
                `/api/v3/job-applications/${jobApplicationId}/evaluate?token=${token}`
              );
              stopRecording(false);
              setShowRatingModal(true);
            } catch (error) {
              toast.error("Failed to end test. Please try again.");
            }
          },
        },
        { label: "Cancel" },
      ],
    });
  };

  const handleRatingSubmit = async (rating) => {
    try {
      const jobApplicationId = localStorage.getItem("jobApplicationId");
      await axiosInstance.post(
        `/api/v3/job-applications/${jobApplicationId}/rating`,
        { rating }
      );
      navigate("/");
    } catch (error) {
      toast.error("Failed to submit rating. Please try again.");
    }
  };

  const togglePopUp = () => {
    setShowRatingModal(true);
  };

  const visibleQuestions = getCurrentIndex();
  return (
    <div className="d-flex align-center justify-content-between test-pagiation test-header">
      <div
        className="view-all-btn tw-cursor-pointer"
        onClick={() =>
          navigate(`/main-test?testId=${questions.id}`, {
            state: { redirected: true },
          })
        }
      >
        <img
          src={viewAllQuestions}
          className="viewAllQuestionsIcon"
          alt="View All Questions Icon"
        />
        <span
          style={{
            color: "#868C8A",
            textDecorationColor: "#868C8A",
            borderBottom: "solid 1px",
          }}
        >
          View all Question List
        </span>
      </div>
      <div className="test-pagination">
        <button
          className="test-pagination-arrow-btn"
          onClick={handlePrevious}
          disabled={leftEnd}
        >
          <img
            className="leftPaginationArrow"
            src={leftPaginationArrow}
            alt="Previous Question"
          />
        </button>
        <div className="test-pagination-btn-group">
          {visibleQuestions.map((stage) => {
            const actualIndex = questions.findIndex((q) => q.id === stage.id);
            return (
              <button
                key={stage.id}
                className={`test-pagination-btn ${stage.id === activeQue ? "active" : ""
                  }`}
                onClick={() => {
                  if (attemptedQuestions[activeQue]) {
                    onQueChange(stage.id);
                  } else if (stage.id !== activeQue) {
                    skipQnCnfirmation(
                      questions?.findIndex((index) => index.id === stage.id)
                    );
                  }
                }}
                style={{
                  backgroundColor: attemptedQuestions[stage.id]
                    ? "#393A3A"
                    : "#191C1B",
                }}
                disabled={attemptedQuestions[stage.id]}
              >
                {attemptedQuestions[stage.id] && (
                  <span className="submittedQnFlag">
                    <img
                      className="rightImage"
                      src={rightImage}
                      alt="Completed"
                    />
                  </span>
                )}
                {actualIndex + 1}
              </button>
            );
          })}
        </div>
        <button
          className="test-pagination-arrow-btn"
          onClick={handleNext}
          disabled={rightEnd}
        >
          <img
            className="rightPaginationArrow"
            src={rightPaginationArrow}
            alt="Next Question"
          />
        </button>
      </div>
      <div className="testTimerEnd">
        <div className="timerClock">
          <img src={clockIcon} className="clockIcon" alt="Clock Icon" />
          <span className="clockText">{timeLeft}</span>
        </div>
        <button type="button" className="testStopBtn" onClick={endTest}>
          <img
            src={testStopIcon}
            className="testStopIcon"
            alt="Stop Test Icon"
          />
          <span>End Test</span>
        </button>
      </div>
      <RatingModal
        isOpen={showRatingModal}
        onClose={() => {
          setShowRatingModal(false);
          navigate("/submissions");
        }}
        onSubmit={handleRatingSubmit}
      />
    </div>
  );
};

export default EditorPagination;
