import { useEffect, useState } from "react";
import "./config.css";
import Loader from "./common/Loader";
{/* Need to find a way to fix this */ }
// import { Document } from 'react-pdf';

export default function Privacy() {
  const [privacyPolicy, setPrivacyPolicy] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTerms = async () => {
      try {
        const response = await fetch(
          `https://api.assessmentpad.com/api/app-configs?configName=PRIVACY`
        );

        if (!response.ok) {
          throw new Error("Failed to fetch privacy policy");
        }

        const data = await response.json();
        setPrivacyPolicy(data.data[0]);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTerms();
  }, []);

  return (
    <div className="terms-conitions landing-page">
      <main className="terms-content">
        {loading && (
          <div className="loading">
            <Loader />
          </div>
        )}
        {error && <div className="error">Error: {error}</div>}
        {privacyPolicy && (
          <>
            <iframe style={{ width: "100%", height: "100%" }} src={privacyPolicy.url}></iframe>
            {/* Need to find a way to fix this */}
            {/* <Document file={{ url: privacyPolicy.url }} /> */}
          </>
        )}
      </main>
    </div>
  );
}
