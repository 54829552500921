import React, { useState } from "react";
import "./FAQ.css";

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "How do I know my eligibility for participating in a test ?",
      answer:
        "Once the company you applied for shortlists your profile, they will reach out to you via email (please make sure to check your spam/junk folders too) , that’s when you know you can go ahead to take up the test.",
    },
    {
      question:
        "Do I need username and password for logging in Assessmentpad platform ?",
      answer: (
        <>
          • If you are a candidate, you will receive an email from the companies'
          relevant recruiter(s), using that link in your email, you can log in.
          <br />
          <br />• If you are a recruiter, please make sure you are one of our 
          partners and access is given to you.
        </>
      ),
    },
    {
      question: "Do I get hired immediately after I pass the test?",
      answer:
        "No, once you have successfully passed the test, your test video will be analysed and then the decision will be taken if you are eligible for further round(s), stay tuned via email or call!!",
    },
  ];

  return (
    <div id="faqs" className="faq-container">
      <div className="faq-header">
        <span className="faq-icon">★</span>
        FAQ
      </div>
      <h2 className="faq-title">Frequently Asked Questions</h2>
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className={`faq-item ${openIndex === index ? "open" : ""}`}
          >
            <button className="faq-question" onClick={() => toggleFAQ(index)}>
              {faq.question}
              <span className="faq-toggle">▼</span>
            </button>
            <div className="faq-answer">
              <p className="ans-description">
                {typeof faq.answer === "string" ? faq.answer : faq.answer}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="faq-footer">
        Still have more questions? Contact us at{" "}
        <a href="mailto:hi@assessmentpad.com">hi@assessmentpad.com</a>
      </div>
    </div>
  );
};

export default FAQ;
