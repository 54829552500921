import React, { useState, useLayoutEffect } from "react";
import "./LandingPage.css";
import { Features } from "./LandingPage/Features";
import HowItWorks from "./LandingPage/HowItWorks";
import FAQ from "./LandingPage/FAQ";
import { useLocation } from "react-router-dom";

const AssessmentPadLandingPage = () => {
  const location = useLocation();

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  useLayoutEffect(() => {
    if (location?.hash) {
      const sectionId = location?.hash.replace('#', '');
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <div className="landing-page">
      <main>
        <section className="hero">
          <h1>
            <span>Monitored Test</span>
            <span style={{ fontStyle: "italic" }}>Platform</span>
          </h1>
          <p>We make hiring meaningful through our monitored test platform</p>
        </section>
        <section className="introduction">
          <div className="intro-label">
            <span className="intro-icon">★</span>
            <span className="intro-text">Introducing AssessmentPad</span>
          </div>
          <h2>
            Assessmentpad is an AI powered online test platform, It ensures our
            partners shortlist the best and genuine applicants out there.
          </h2>
        </section>

        <section style={{ marginTop: 40 }} className="introduction">
          <div className="intro-label">
            <span className="intro-icon">★</span>
            <span className="intro-text">What you'll get</span>
          </div>
          <h2>We resolve problems associated with painful hiring procedures</h2>
        </section>

        <Features />
        <HowItWorks />
        <FAQ />
      </main>
    </div>
  );
};

export default AssessmentPadLandingPage;
