import { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchJobs } from "../../../redux/slices/jobsSlice";
import "./recruiterCandidate.scss";
import NoList from "../../../assets/images/no-list.svg";
import "./recruiterCandidate.scss";
import DropdownFilter from "../../common/DropdownFilter";
import axios from "axios";
import { JOBS_V3 } from '../../../constants/API';
import RefreshIcon from "../../../assets/images/refresh.png";
import leftPaginationArrow from "../../../assets/images/leftPaginationArrow.svg";
import rightPaginationArrow from "../../../assets/images/rightPaginationArrow.svg";

const candidatesPerPage = 10;

export default function RecruiterCandidate() {
  const dispatch = useDispatch();
  const { jobs, loading } = useSelector((state) => state.jobs);
  const [selectedJob, setSelectedJob] = useState("");
  const [selectedTest, setSelectedTest] = useState("");
  const [tests, setTests] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [loadingTests, setLoadingTests] = useState(false);
  const [loadingCandidates, setLoadingCandidates] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [buttonRange, setButtonRange] = useState({ start: 1, end: 4 });
  const [totalPages, setTotalPages] = useState(0);
  const showList = selectedJob && selectedTest;

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    dispatch(fetchJobs({ token: user._token, recruiterId: user.recruiterId }));
  }, [dispatch]);

  const jobValues = useMemo(() => {
    return jobs.map((job) => job.title);
  }, [jobs]);

  const fetchTests = async (jobId) => {
    const user = JSON.parse(localStorage.getItem("user"));
    try {
      setLoadingTests(true);
      const response = await axios.get(
        `${JOBS_V3}/${jobId}/tests?token=${user._token}`
      );
      setTests(response.data.data);
    } catch (error) {
      console.error("Error fetching tests:", error);
    } finally {
      setLoadingTests(false);
    }
  };

  const handleSelectJob = (value) => {
    setSelectedJob(value);
    setTotalPages(0);
    const selectedJobData = jobs.find((job) => job.title === value);

    if (selectedJobData?.id) {
      fetchTests(selectedJobData.id);
    }
    setSelectedTest("");
  };

  const handleSelectTest = (value) => {
    setSelectedTest(value);
    const selectedJobData = jobs.find((job) => job.title === selectedJob);
    const testId = tests.find((t) => t.title === value).testId;
    if (selectedJobData?.id && value) {
      fetchCandidates(selectedJobData.id, testId);
    }
  };

  const handlePrevRange = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
    if (buttonRange.start >= currentPage) {
      const prevStart = Math.min(buttonRange.start - 1, totalPages - 10);
      const prevEnd = Math.min(buttonRange.end - 1, totalPages);
      if (currentPage <= buttonRange.start) {
        setButtonRange({ start: prevStart, end: prevEnd });
      }
    }
  };

  const renderPaginationButtons = () => {
    const buttons = [];
    for (let i = buttonRange.start; i <= buttonRange.end; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => setCurrentPage(i)}
          className={i === currentPage ? "active" : ""}
        // disabled={i > Math.ceil(filteredQuestions.length / 10)}
        >
          {i}
        </button>
      );
    }
    return buttons;
  };

  const handleNextRange = () => {
    if (totalPages > currentPage) {
      setCurrentPage(currentPage + 1);
    }
    if (buttonRange.end <= currentPage) {
      const nextStart = Math.min(buttonRange.start + 1, totalPages - 10 + 1);
      const nextEnd = Math.min(buttonRange.end + 1, totalPages);
      if (currentPage < totalPages) {
        setButtonRange({ start: nextStart, end: nextEnd });
      }
    }
  };
  useEffect(() => {
    const selectedJobData = jobs.find((job) => job.title === selectedJob);
    const testId = tests.find((t) => t.title === selectedTest)?.testId;
    if (selectedJobData?.id && selectedTest) {
      fetchCandidates(selectedJobData.id, testId);
    }
  }, [currentPage])

  // Transform tests data for dropdown
  const testValues = useMemo(() => {
    return tests.map((test) => ({
      value: test.id,
      label: test.title || "-",
    }));
  }, [tests]);

  const fetchCandidates = async (jobId, testId) => {
    try {
      setLoadingCandidates(true);
      const token = JSON.parse(localStorage.getItem("auth"));
      const response = await axios.get(`${JOBS_V3}/${jobId}/candidates`, {
        params: {
          page: currentPage - 1,
          token,
          testId,
        },
      });
      setCandidates(response.data.data.data || []);
      setTotalPages(response.data.data.totalPages || 0)
      setButtonRange({ start: 1, end: response.data.data.totalPages })
    } catch (error) {
      console.error("Error fetching candidates:", error);
      setCandidates([]);
    } finally {
      setLoadingCandidates(false);
    }
  };

  const onRefreshList = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    const selectedJobData = jobs.find((job) => job.title === selectedJob);
    const testId = tests.find((t) => t.title === selectedTest)?.testId;
    if (selectedJobData?.id && selectedTest) {
      fetchCandidates(selectedJobData.id, testId);
    }
  }

  return (
    <div className="candidates-main-container">
      <div className="flex justify-between mb-4 filter-container">
        <div className="filter-section">
          <div className="w-[48%]">
            <div className="relative">
              <DropdownFilter
                name="questionType"
                styleClass="viewAllQnPageDropdown"
                dropdownName={selectedJob || "Select Job"}
                dropdownOptions={jobValues}
                dropdownHandleClick={handleSelectJob}
                isLoading={loading}
              />
            </div>
          </div>
          <div className="w-[48%] tw-ml-10">
            {selectedJob && (
              <div className="relative">
                <DropdownFilter
                  name="questionType"
                  styleClass="viewAllQnPageDropdown"
                  dropdownName={
                    selectedTest && selectedTest != ""
                      ? selectedTest
                      : "Select Test"
                  }
                  dropdownOptions={testValues.map((v) => v.label)}
                  dropdownHandleClick={handleSelectTest}
                  isLoading={loadingTests}
                />
              </div>
            )}
          </div>
          {selectedJob && selectedTest &&
            <div className="refresh-list tw-ml-10">
              <img
                src={RefreshIcon}
                alt="Update New Resume"
                className="tw-cursor-pointer"
                title="Refresh list"
                onClick={onRefreshList}
              />
            </div>
          }
        </div>
      </div>

      {!showList ? (
        <div className="no-list-container">
          <div className="text-center">
            {/* <img src={NoList} /> */}
            <h3 className="no-list-text">No list to show</h3>
            <p className="text-description">
              Select from the dropdown above to
              <br />
              view candidate list
            </p>
          </div>
        </div>
      ) : loadingCandidates ? (
        <div className="loading-container">
          <p>Loading candidates...</p>
        </div>
      ) : candidates.length === 0 ? (
        <div className="no-list-container">
          <div className="text-center">
            <img src={NoList} alt="No candidates" />
            <h3 className="no-list-text">No candidates found</h3>
            <p className="text-description">
              There are no candidates available for this job and test
              combination
            </p>
          </div>
        </div>
      ) : (
        <>
          <div className="candidate-list">
            {
              candidates.map((candidate) => {
                const {
                  candidateTest,
                  candidate: candidateDetails,
                  metadata,
                  avatarColor,
                  tags,
                  jobApplicationId
                } = candidate;
                return (
                  <div key={candidateDetails.id + jobApplicationId} className="candidate-card">
                    <div className="candidate-info">
                      <div
                        className={`avatar ${avatarColor || "blue"}`}
                        style={{ height: 60, width: 60 }}
                      >
                        {candidateDetails?.name
                          ?.split(" ")
                          .map((n) => n[0])
                          .join("")}
                      </div>
                      <div className="details">
                        <h3>{candidateDetails.name}</h3>
                        <div className="contact-info">
                          <a href={`mailto:${candidateDetails.email}`}>
                            {candidateDetails.email}
                          </a>
                          <span style={{ color: "#868C8A" }}>{candidateDetails.mobileNumber}</span>
                        </div>
                        <div className="tags">
                          {tags?.map((tag) => (
                            <span key={tag} className="tag">
                              {tag}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="score">
                      <h3>{candidateTest.finalScore}</h3>
                    </div>
                    <div className="actions">
                      <button
                        type="button"
                        className="view-video"
                        style={candidateTest?.videoReadinessStatus ? {
                          backgroundColor: "#B4FF45",
                          color: "#131514",
                          fontWeight: 600,
                        } : {}}
                        disabled={!candidateTest?.videoReadinessStatus}
                        onClick={() => window.open(candidateTest.videoPreSignUrl, "_blank")}
                      >
                        View Video
                      </button>
                      <button
                        className="view-resume"
                        onClick={() => window.open(metadata.preSignedS3KeyForResume, "_blank")}
                      >
                        View Resume
                      </button>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </>
      )}
      {totalPages > 0 && <div className="candidatePagination">
        <div className="paginationNoBtns">
          {renderPaginationButtons()}
        </div>
        <div style={{ display: "flex" }}>
          <button onClick={handlePrevRange} disabled={currentPage === 1}>
            <img src={leftPaginationArrow} alt="Previous" />
          </button>
          <button
            onClick={handleNextRange}
            disabled={currentPage === totalPages}
          >
            <img src={rightPaginationArrow} alt="Next" />
          </button>
        </div>
      </div>}
    </div>
  );
}
