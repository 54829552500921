import { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./questions-panel.scss";
import rightPaginationArrow from "../../../assets/images/rightPaginationArrow.svg";
import leftPaginationArrow from "../../../assets/images/leftPaginationArrow.svg";
import { fetchQuestions } from "../../../redux/actions/questionsActions";
import {
  addSelectedQuestion,
  removeSelectedQuestion,
} from "../../../redux/slices/questions/recruiterQuestionSlice";
import CategoryType from "../QuestionBank/CategoryType";
import DifficultyLevel from "../QuestionBank/DifficultyLevel";
import styles from "../QuestionBank/questions-list.module.css";
import { COUNT_LIMIT, ITEMS_PER_PAGE_LIMIT } from '../../types/QuestionsSection';

const questionsPerPage = ITEMS_PER_PAGE_LIMIT;

export default function QuestionsPanel({ isOpen, onClose }) {
  const dispatch = useDispatch();
  const {
    questions,
    selectedQuestions,
    loading,
    error,
    recruiterId,
    // totalPages,
  } = useSelector((state) => state.recruiterQuestions);

  const user = JSON.parse(localStorage.getItem("user"));
  const role = localStorage.getItem("role");

  const [currentPage, setCurrentPage] = useState(1);
  const [buttonRange, setButtonRange] = useState({ start: 1, end: 10 });
  const [questionType, setQuestionType] = useState("ALL");
  const [difficultyType, setDifficultyType] = useState("ALL");
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    setTotalPages(Math.ceil(questions.length / ITEMS_PER_PAGE_LIMIT));
  }, [questions]);

  useEffect(() => {
    dispatch(
      fetchQuestions(
        currentPage - 1,
        COUNT_LIMIT,
        role === "admin" ? recruiterId : user.recruiterId
      )
    );
  }, [dispatch, currentPage]);

  const handlePrevRange = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);

      if (currentPage <= buttonRange.start) {
        setButtonRange((prev) => ({
          start: Math.max(prev.start - 10, 1),
          end: prev.start - 1,
        }));
      }
    }
  };

  const handleNextRange = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);

      if (currentPage >= buttonRange.end) {
        setButtonRange((prev) => ({
          start: prev.start + 10,
          end: Math.min(prev.end + 10, totalPages),
        }));
      }
    }
  };

  const renderPaginationButtons = () => {
    const buttons = [];
    for (
      let i = buttonRange.start;
      i <= Math.min(buttonRange.end, totalPages);
      i++
    ) {
      buttons.push(
        <button
          key={i}
          onClick={() => setCurrentPage(i)}
          // className={i === currentPage ? styles.active : ""}
          className={i === currentPage ? "active" : ""}
        >
          {i}
        </button>
      );
    }
    return buttons;
  };
  const handleQuestionToggle = (question) => {
    const isSelected = selectedQuestions.some((q) => q.id === question.id);
    if (isSelected) {
      dispatch(removeSelectedQuestion(question));
    } else {
      dispatch(addSelectedQuestion(question));
    }
  };

  const filteredQuestionsList = useMemo(() => {
    if (!questions.length) return [];

    let filtered = [...questions];
    if (questionType !== "ALL" || difficultyType !== "ALL") {
      filtered = filtered.filter(
        (elem) => elem.questionType === questionType || elem.difficultyLevel === difficultyType
      );
    }

    // remove below to support server side pagination
    setTotalPages(Math.ceil(filtered.length / ITEMS_PER_PAGE_LIMIT));

    return filtered;
  }, [questions, questionType, difficultyType]);

  const currentPageQuestions = useMemo(() => {
    const startFilterIndex = ((currentPage - 1) * ITEMS_PER_PAGE_LIMIT);
    const endFilterIndex = (currentPage * ITEMS_PER_PAGE_LIMIT);
    return filteredQuestionsList.slice(startFilterIndex, endFilterIndex);
  }, [currentPage, filteredQuestionsList]);

  return (
    <div className={`questions-panel ${isOpen ? "open" : ""}`}>
      <div className="questions-header">
        <h2>All questions</h2>
      </div>

      {/* <div className="search-container">
        <input
          type="text"
          placeholder="Search questions"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="search-input"
        />
      </div> */}
      <div className={styles.listHeader}>
        <div className={styles.titleHeader}>TITLE</div>
        <div className={styles.typeHeader}>
          <CategoryType onChange={(type) => setQuestionType(type)} selectedItem={questionType} />
        </div>
        <div className={styles.typeHeader}>
          <DifficultyLevel onChange={(type) => setDifficultyType(type)} selectedItem={difficultyType} />
        </div>
        <div className={styles.typeHeader}></div>
      </div>

      <div className="questions-list">
        {loading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>Error: {error}</div>
        ) : currentPageQuestions.length > 0 ? (
          currentPageQuestions.map((question, index) => (
            <div key={question.id} className="question-item">
              <div className="question-info">
                <div className="question-title">
                  <span className="question-number column-id">
                    {(currentPage - 1) * questionsPerPage +
                      index +
                      1 +
                      ". "}
                  </span>
                  <span className="column-title" style={{ textAlign: "center" }}>{question.title}</span>
                </div>

                <span className="question-type column-type">
                  {question.questionType}
                </span>
                <span className="question-type column-type">
                  {question.difficultyLevel}
                </span>
                <button
                  className={
                    selectedQuestions.some((q) => q.id === question.id)
                      ? "added-button"
                      : "add-button"
                  }
                  onClick={() => handleQuestionToggle(question)}
                >
                  {selectedQuestions.some((q) => q.id === question.id)
                    ? "Remove"
                    : "+Add"}
                </button>
              </div>
            </div>
          ))
        ) : questions.length > 0 ? (
          <h1 className="text-center text-warning">
            No questions match the selected filters
          </h1>
        ) : (
          <h1 className="text-center text-warning">No questions available</h1>
        )}
      </div>

      <div className="pagination">
        <div className="paginationNoBtns">{renderPaginationButtons()}</div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>
            <div className="paginationNextPrevBtn">
              <button
                className="leftPaginationArrow"
                onClick={handlePrevRange}
                disabled={currentPage === 1}
              >
                <img
                  className="rightPaginationArrow"
                  src={leftPaginationArrow}
                  alt="Next Question"
                />
              </button>
              <button
                className="rightPaginationArrow"
                onClick={handleNextRange}
                disabled={currentPage === totalPages}
              >
                <img
                  className="rightPaginationArrow"
                  src={rightPaginationArrow}
                  alt="Next Question"
                />
              </button>
            </div>
            <div>
              <button className="done-button" onClick={onClose}>
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
