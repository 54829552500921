import { useEffect, useState } from "react";
import axiosInstance from "../../axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { markQuestionAttempted } from "../../redux/slices/questions/questionSlice";
import { encode as base64_encode } from "base-64";
import { toast } from "react-toastify";
import axios from "axios";

const MCQPage = ({ data, candidateTestId }) => {
  const [selectedValue, setSelectedValue] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const selector = useSelector((state) => state.questions);
  const dispatch = useDispatch();
  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    console.log({ value });
    if (selectedValue.includes(value)) {
      setSelectedValue(selectedValue.filter((item) => item !== value)); // Remove the value if it's already selected
    } else {
      setSelectedValue([...selectedValue, value]); // Add the value if it's not selected
    }
  };

  const submitAnswer = async () => {
    setIsLoading(true);

    try {
      const token = localStorage.getItem("auth");
      const payload = {
        input:
          data.metadata.category === "SINGLE_SELECT"
            ? base64_encode(selectedValue[0])
            : "",
        candidateTestId: candidateTestId,
        questionId: data.id,
      };
      const response = await axios.post(
        `https://api.assessmentpad.com/api/v4/candidate-tests/answers/submit?token=${token}`,
        payload,

        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 202) {
        console.log({ data, selectedValue });
        dispatch(
          markQuestionAttempted({
            questionId: data.id,
            submission: {
              selectedOptions: selectedValue,
              timestamp: new Date().toISOString(),
            },
          })
        );

        toast.success("Answer submitted successfully!");
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to submit answer");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="test-page-main">
      <div className="mcqQuestionPanel">
        <div className="w-50 mcqQuestionDesc">{data.title}</div>
        <div className="w-50 mcqQuestionDesc">{data.description}</div>
        <ul className="macq-radio-grid mcqOptions w-50">
          <span className="mcqOptionItemsTitle">SELECT ONLY ONE</span>
          {data.metadata.options.map((opt, i) => {
            return (
              <li key={i} className="mcqOptionItems w-75">
                <input
                  type={`${
                    data.metadata.category === "SINGLE_SELECT"
                      ? "radio"
                      : "checkbox"
                  }`}
                  name={opt}
                  value={opt}
                  checked={selectedValue.includes(opt)}
                  onChange={(val) => {
                    console.log({ val: val.target.value });
                    data.metadata.category === "SINGLE_SELECT"
                      ? setSelectedValue([val.target.value])
                      : handleCheckboxChange(val);
                  }}
                  disabled={selector.attemptedQuestions[data.id]}
                />
                <label htmlFor={opt}>{opt}</label>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="bottom-action-panel">
        <button
          type="button"
          disabled={!selectedValue.length || isLoading}
          className="action-btn"
          onClick={submitAnswer}
        >
          {selector.attemptedQuestions[data.id]
            ? " Submitted Successfully"
            : isLoading
            ? "Submitting..."
            : "Submit"}
          {isLoading && (
            <div
              className="spinner-border spinner-border-sm ml-2"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          )}
        </button>
      </div>
    </div>
  );
};

export default MCQPage;
