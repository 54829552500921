import React from "react";
import DropdownFilter from "../../common/DropdownFilter";
import { QuestionsType } from '../../types/QuestionsSection';
import "./categoryType.scss";

const CategoryType = ({ onChange, selectedItem, styleClass = "question-type" }) => {
  return (
    <DropdownFilter
      name="quetionType"
      styleClass={styleClass}
      dropdownName={"Type"}
      dropdownOptions={["ALL", ...Object.values(QuestionsType)]}
      dropdownHandleClick={onChange}
      selectedItem={selectedItem}
    />
  );
};

export default CategoryType;
