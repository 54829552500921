import React, { useContext, useEffect } from "react";
import "./App.css";
import "./styles/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Login from "./components/Login";
import Signup from "./components/Signup";
import CodeEditor from "./components/Editor";
import Congrats from "./components/Congrats";
import Otp from "./components/Otp";
import Choose from "./components/Choose";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Organization from "./components/Organization";
import Cvacancy from "./components/CreateVacancy";
import Rhome from "./components/recruiter/Rhome";
import Thankyou from "./components/Thankyou";
import Term from "./components/Term";
import Rpost from "./components/Rpost";
import Chome from "./components/candidate/Chome";
import Applied from "./components/AppliedCandidatePage/AppliedCandidates";
import Sucess from "./components/Sucess";
// import MainHome from "./components/MainHome";
import Contactus from "./components/Contactus";
import About from "./components/About";
import Features from "./components/Features";
import Faq from "./components/Faq";
import Starttest from "./components/Starttest";
import Privacy from "./components/Privacy";
import Resumeupload from "./components/Resumeupload";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FormDataProvider } from "./context/formdatacontext";
import { Redirect } from "react-router-dom";
import { Navigate, useNavigate } from "react-router-dom";
import { pdfjs } from 'react-pdf';

import "font-awesome/css/font-awesome.min.css";
import NewHome from "./home";
import TestPermissionPage from "./components/TestPermissionPage";
import { Provider } from "react-redux";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { setTheme, setUser } from "./redux/slices/auth/authSlice";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./toast-override.scss";
import recordContext from "./context/recordContext";
import PreTestArena from "./components/PreTestArena";
import QuesListingPage from "./components/CodeEditor/QuesListingPage";
import ErrorPage from "./components/common/ErrorPage";

import "./styles/main.scss";
import AssessmentPadLandingPage from "./components/AssessmentPadLandingPage";
import TermsAndConditions from "./components/TermsAndConditions";
import CreateQuestions from "./components/recruiter/CreateQuestions/CreateQuestions";
import AdminLogin from "./components/AdminLogin";
import CreateJobNew from "./components/recruiter/CreateJobNew";
import LegalDocs from "./components/admin/LegalDocs";
import MainLayout from "./components/layout/MainLayout";
import RecruiterQuestionLists from "./components/recruiter/QuestionBank/RecruiterQuestionLIsts";
import RecruiterCandidate from "./components/recruiter/Candidates/RecruiterCandidates";
import PublicLayout from "./components/layout/PublicLayout";
import { DraggableVideo } from "./components/DraggableVideo";

function App() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { theme } = useAppSelector((state) => state.auth);
  const context = useContext(recordContext);
  const { mainVideoRef, camVideoRef, isVideoRecording } = context;

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = new URL(
      'pdfjs-dist/build/pdf.worker.min.mjs',
      import.meta.url,
    ).toString();
  }, []);

  useEffect(() => {
    const user = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null;
    if (user) {
      dispatch(setUser(user));
    }
    // setting to default dark theme
    dispatch(setTheme("dark"));
  }, []);

  const ProtectedRoute = ({
    component: Component,
    isAuthenticated,
    ...rest
  }) => {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? <Component {...props} /> : navigate("/login")
        }
      />
    );
  };

  const isAuthenticated = localStorage.getItem("auth") !== null;
  const isRecruiterRole = localStorage.getItem("role") === "recruiter";
  const isCandidateRole = localStorage.getItem("role") === "candidate";
  const isAdmin = localStorage.getItem("role") === "admin";

  return (
    <>
      <video ref={mainVideoRef} className="main-video d-none"></video>
      <DraggableVideo isVideoRecording={isVideoRecording} camVideoRef={camVideoRef} />
      <FormDataProvider>
        <div className={`App theme-${theme} app-box`}>
          <Routes>
            {/* Public routes that don't need header can be outside MainLayout */}
            <Route path="/temp-code" element={<CodeEditor />} />
            <Route path="/login" element={<Login />} />
            <Route path="/admin-login" element={<AdminLogin />} />
            <Route path="/signup" element={<Signup />} />
            <Route
              path="/main-test"
              element={isAuthenticated ? <QuesListingPage /> : <ErrorPage />}
            />
            <Route path="/otp" element={<Otp />} />

            {/* Routes that need header will be nested inside MainLayout */}
            <Route element={<MainLayout />}>
              <Route path="/congrats" element={<Congrats />} />
              <Route path="/choose" element={<Choose />} />
              <Route path="/organization" element={<Organization />} />
              <Route path="/submissions" element={<Thankyou />} />
              <Route path="/create-vacancy" element={<Cvacancy />} />
              <Route path="/rpost" element={<Rpost />} />
              <Route path="/terms-and-conditions" element={<Term />} />
              <Route path="/features" element={<Features />} />
              <Route path="/about-us" element={<About />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/resume" element={<Resumeupload />} />
              <Route path="/pre-test-arena" element={<PreTestArena />} />
              <Route path="/test-arena" element={<TestPermissionPage />} />
              <Route path="/applied-candidates-view" element={<Applied />} />
              <Route
                path="/sucessful-candidates-view/:jobid"
                element={<Sucess />}
              />
              <Route
                path="/candidates-view"
                element={
                  isAuthenticated && isCandidateRole ? (
                    <Chome />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route
                path="/code"
                element={
                  isAuthenticated && isCandidateRole ? (
                    <CodeEditor />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route
                path="/starttest"
                element={
                  isAuthenticated && isCandidateRole ? (
                    <Starttest />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route
                path="/recruiters-view"
                element={
                  isAuthenticated && isRecruiterRole ? (
                    <Rhome />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route
                path="/questions/:id"
                element={
                  isAuthenticated && (isRecruiterRole || isAdmin) ? (
                    <CreateQuestions />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route
                path="/question-bank"
                element={
                  isAuthenticated && (isRecruiterRole || isAdmin) ? (
                    <div className="h-100">
                      <RecruiterQuestionLists />
                    </div>
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route
                path="/create-question"
                element={
                  isAuthenticated && (isRecruiterRole || isAdmin) ? (
                    <CreateQuestions />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route
                path="/admin-view"
                element={
                  isAuthenticated && isAdmin ? (
                    <Rhome />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route
                path="/admin-view/questions/:id"
                element={
                  isAuthenticated && isAdmin ? (
                    <CreateQuestions />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route
                path="/legal-docs"
                element={isAuthenticated && isAdmin && <LegalDocs />}
              />
              <Route
                path="/candidates"
                element={
                  isAuthenticated &&
                  (isRecruiterRole || isAdmin) && <RecruiterCandidate />
                }
              />
              <Route
                path="/create-job"
                element={
                  isAuthenticated &&
                  (isRecruiterRole || isAdmin) && <CreateJobNew />
                }
              />
            </Route>

            <Route element={<PublicLayout />}>
              <Route path="/" element={<AssessmentPadLandingPage />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<TermsAndConditions />} />
              <Route path="/contact-us" element={<Contactus />} />
            </Route>
          </Routes>
        </div>
      </FormDataProvider>
      <ToastContainer
        position="top-right"
        autoClose={15000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}

export default App;
