import React, { useRef, useState, useEffect, useCallback } from "react";
import RecordContext from "./recordContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import axiosInstance from "../axiosInstance";
import { toast } from "react-toastify";

const RecordProvider = (props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const uploadCounterRef = useRef(1);

  const handleNavigation = () => {
    navigate("/submissions");
  };

  const mainVideoRef = useRef(null);
  const camVideoRef = useRef(null);
  const mediaRecorderRef = useRef(null); // This ref will store the mediaRecorder instance

  const isRecording = useRef(false);
  const [isVideoRecording, setIsVideoRecording] = useState(false);
  const [streams, setStreams] = useState({});

  const isOnline = useRef(window.navigator.onLine);
  const videoChunks = useRef(new Array());

  useEffect(() => {
    const handleOnline = () => {
      // setIsOnline(true);
      isOnline.current = true;
      uploadPendingVideos();
    };
    const handleOffline = () => {
      isOnline.current = false;
      // setIsOnline(false)
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  const onDataAvailable = useCallback((streamData, counter) => {
    if (isOnline.current) {
      uploadChuksVideo(streamData, counter)
    } else {
      storePendingUpload(streamData, counter);
    }
  }, [isOnline.current]);

  const dataEventHandler = (event) => {
    if (event.data.size > 0) {
      videoChunks.current.push(event.data);
    }
  };

  const captureScreen = async () => {
    let captureStream = null;
    try {
      captureStream = await navigator.mediaDevices.getDisplayMedia({
        video: {
          displaySurface: "monitor",
          cursor: "always",
          mediaSource: "screen",
        },
        audio: true
      });

      const settings = captureStream.getVideoTracks()[0].getSettings();

      captureStream.getVideoTracks()[0].onended = () => {
        console.log(">>>>> SCREEN CAPTURE ENDED >>>>>>>>");
        captureStream.getTracks().forEach((track) => track.stop());
        stopAllStreams();
        setStreams({});
        setIsVideoRecording(false);
        if (
          mediaRecorderRef.current &&
          mediaRecorderRef.current.state === "recording"
        ) {
          stopRecording();
        }
      };

      setStreams((prev) => ({ ...prev, screen: captureStream }));
      mainVideoRef.current.srcObject = captureStream;

      if (settings.displaySurface === "monitor") {
        console.log(">>>>> SCREEN CAPTURED SUCCESSFULLY >>>>>>>>");
        return "SUCCESS";
      } else {
        stopAllStreams();
        toast.error("Kindly share entire screen");
        return "SHARE_ENTIRE_SCREEN";
      }
    } catch (error) {
      console.log(">>>>>> SCREEN CAPTURED FAILED >>>>>>>", error);
      if (error === "SHARE_ENTIRE_SCREEN") {
        toast.error("Kindly share entire screen");
      } else {
        toast.error("Failed to share screen");
      }
      return "FAIL";
    }
  };

  const captureUserMedia = async () => {
    try {
      const userStream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      setStreams((prev) => ({ ...prev, camera: userStream }));
      userStream.getVideoTracks()[0].onended = () => {
        console.log(">>>>> USER MEDIA ENDED >>>>>>>>");
      };
      camVideoRef.current.srcObject = userStream;
      setIsVideoRecording(true);
      console.log(">>>>> USER MEDIA CAPTURED SUCCESSFULLY >>>>>>>>");
      return "SUCCESS";
    } catch (error) {
      console.log(">>>>>> USER MEDIA CAPTURED FAILED >>>>>>>");
      toast.error("Kindly enable camera and microphone settings");
      return "FAIL";
    }
  };

  const storePendingUpload = async (videoChunk, counter) => {
    try {
      // Store in IndexedDB
      const db = await openIndexedDB();
      const transaction = db.transaction(["pendingUploads"], "readwrite");
      const store = transaction.objectStore("pendingUploads");

      const upload = {
        id: Date.now(),
        chunk: videoChunk,
        timestamp: new Date(),
        email: JSON.parse(localStorage.getItem("email")),
        jobId: searchParams.get("jobId"),
        counter
      };

      await store.add(upload);
    } catch (error) {
      console.error("Error storing video chunk:", error);
    }
  };

  const openIndexedDB = () => {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open("videoStorage", 1);

      request.onerror = () => reject(request.error);
      request.onsuccess = () => resolve(request.result);

      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains("pendingUploads")) {
          db.createObjectStore("pendingUploads", { keyPath: "id" });
        }
      };
    });
  };
  const uploadPendingVideos = async () => {
    if (!isOnline) return;

    try {
      const db = await openIndexedDB();
      const transaction = db.transaction(["pendingUploads"], "readwrite");
      const store = transaction.objectStore("pendingUploads");
      const pendingUploads = store.getAll() || [];

      pendingUploads.onsuccess = () => {
        console.log("pendingUploads.result", pendingUploads.result);
        for (const upload of pendingUploads.result) {
          try {
            uploadChuksVideo(upload.chunk, upload.counter).then(() => {
            // Remove from IndexedDB after successful upload
              store.delete(upload.id);
            });
          } catch (error) {
            console.error("Error uploading pending video:", error);
          }
        }
      }
    } catch (error) {
      console.error("Error processing pending uploads:", error);
    }
  };

  const uploadChuksVideo = async (chunks, counter) => {
    console.log("uploadChuksVideo called");
    try {
      const email = JSON.parse(localStorage.getItem("email"));
      const token = localStorage.getItem("auth");
      const jobApplicationId = localStorage.getItem("jobApplicationId");
      const url = `/v1/video/publish`;
      const formData = new FormData();
      formData.append("emailId", email);
      formData.append("candidateJobId", jobApplicationId);
      formData.append("token", token);
      formData.append("file", chunks);
      formData.append("counter", counter);
      const response = await axiosInstance.post(url, formData, { headers: {
                "Content-Type": "multipart/form-data",
      }
      });
      //uploadCounterRef.current = uploadCounterRef.current + 1;
      console.log("uploadChuksVideo", response.data);
    } catch (error) {}
  };

  const startRecording = () => {
    try {
      const combinedTracks = Object.values(streams).flatMap((s) =>
        s.getTracks()
      );
      const combinedStream = new MediaStream(combinedTracks);

      mediaRecorderRef.current = new MediaRecorder(combinedStream);
      console.log("MediaRecorder initialized", mediaRecorderRef.current.state);
      mediaRecorderRef.current.ondataavailable = dataEventHandler;

      mediaRecorderRef.current.onstop = onVideoStopped;
      mediaRecorderRef.current.start(70000);

      // restart timer after 1minute
      setTimeout(() => {
        mediaRecorderRef.current.stop();
      }, 60000);

      console.log("After start called:", mediaRecorderRef.current.state);
      isRecording.current = true;
      return "SUCCESS";
    } catch (error) {
      toast.error("Error in Recording");
      return "FAIL";
    }
  };

  const stopAllStreams = () => {
    Object.values(streams).forEach((stream) => {
      stream.getTracks().forEach((track) => {
        track.stop();
      });
    });

    if (mainVideoRef.current && mainVideoRef.current.srcObject) {
      mainVideoRef.current.srcObject.getTracks().forEach((track) => {
        track.stop();
      });
      mainVideoRef.current.srcObject = null;
    }

    if (camVideoRef.current && camVideoRef.current.srcObject) {
      camVideoRef.current.srcObject.getTracks().forEach((track) => {
        track.stop();
      });
      camVideoRef.current.srcObject = null;
    }
  };

  const stopRecording = (shouldNavigate = true) => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === "recording"
    ) {
      isRecording.current = false;
      mediaRecorderRef.current.stop();
      stopAllStreams();
      setStreams({});
      if (shouldNavigate) {
        handleNavigation();
      }
    } else {
      console.error(
        "Recording has not started or mediaRecorder is not initialized."
      );
    }
  };

  const onVideoStopped = async () => {
    console.log("uploaded stopped");
    onDataAvailable(new Blob(videoChunks.current.slice()), uploadCounterRef.current);
    uploadCounterRef.current = uploadCounterRef.current + 1;
    videoChunks.current = [];

    if (isRecording.current) {
      startRecording();
    }

    if (isRecording.current === false) {
      uploadCounterRef.current = 1;
      localStorage.removeItem("jobId");
    }
  };

  // Toggle PiP function
  const togglePiP = async () => {
    try {
      if (document.pictureInPictureEnabled) {
        if (document.pictureInPictureElement) {
          await document.exitPictureInPicture();
          return "SUCCESS";
        } else {
          if (camVideoRef.current) {
            await camVideoRef.current.requestPictureInPicture();
            return "SUCCESS";
          }
        }
      } else {
        new Error("Picture-in-Picture is not supported by this browser.");
        alert("Picture-in-Picture is not supported by this browser.");
      }
    } catch (error) {
      console.log(">>>>>> PIP FAILED >>>>>>>", error);
      return "FAIL";
    }
  };

  return (
    <RecordContext.Provider
      value={{
        mainVideoRef,
        camVideoRef,
        isVideoRecording,
        captureScreen,
        captureUserMedia,
        startRecording,
        stopRecording,
        togglePiP,
      }}
    >
      {props.children}
    </RecordContext.Provider>
  );
};
export default RecordProvider;
