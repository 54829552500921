import JobsList from "./JobsList";
import "./Chome.css";
import AppNav from "../AppNav";
import Button from "../Button";

import { useNavigate } from "react-router-dom";
import CandidateDetails from "./CandidateDetails";

export default function Chome() {
  const navigate = useNavigate();
  const viewAllQuestionHandler = () => {
    navigate(`/main-test?testId=${"67368713-dd22-412c-a97e-a1cad8132c3d"}`);
  };

  const userDetails = localStorage.getItem("userDetails" || null);
  if (userDetails && userDetails.firstName) {
    return <CandidateDetails />;
  }

  return (
    <>
      <Button onClick={viewAllQuestionHandler} text={"View All Questions"} />
      <JobsList />
    </>
  );
}
