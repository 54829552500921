import React, { useState, useEffect } from "react";
import { Document } from 'react-pdf';
import Loader from "./common/Loader";
import "./TermsAndConditions.css";

const TermsAndConditions = () => {
  const [termsData, setTermsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTerms = async () => {
      try {
        const response = await fetch(
          `https://api.assessmentpad.com/api/app-configs?configName=TERMS_AND_CONDITIONS`
        );

        if (!response.ok) {
          throw new Error("Failed to fetch terms and conditions");
        }

        const data = await response.json();
        setTermsData(data.data[0]);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTerms();
  }, []);

  return (
    <div className="terms-conitions landing-page">
      <main className="terms-content">
        {loading && <div className="loading">Loading...</div>}
        {error && <div className="error">Error: {error}</div>}
        {termsData && (
          <>
            <iframe style={{ width: "100%", height: "100%" }} src={termsData.url}></iframe>
            {/* Need to find a way to fix this */}
            {/* <Document file={termsData.url} /> */}
          </>
        )}
      </main>
    </div>
  );
};

export default TermsAndConditions;
